<template>
  <section class="section">
    <p class="text-muted">{{ getTranslation('resultsFoundWithQuantity', pagination.total) }}</p>
    <table class="table-3 table-borderless">
      <thead class="table-3__head scroll-here">
      <tr class="table-3__row">
        <th colspan="2" class="table-3__cell  table__hcell">
          <form method="get" @submit.prevent="fetchItems">
            <div class="field-advanced">
              <div class="field-advanced__inner">
                <div class="field-advanced__field-wrap">
                  <input v-model="filterForm.userQuery" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                </div>

                <div class="field-advanced__right">
                  <button class="btn btn-primary">
                    <svg class="btn__icon">
                      <use xlink:href="#icon__search"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </th>
        <th class="table-3__cell  table__hcell">
          <v-select
              :options="roles"
              :reduce="item=>item.id"
              label="slug"
              v-model="filterForm.level_id"
              :placeholder="getTranslation('selectFromList')"
          />
        </th>
        <th class="table-3__cell  table__hcell">
          <form method="get" @submit.prevent="fetchItems">
            <div class="field-advanced">
              <div class="field-advanced__inner">
                <div class="field-advanced__field-wrap">
                  <input v-model="filterForm.ip" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('ip')">
                </div>

                <div class="field-advanced__right">
                  <button class="btn btn-primary">
                    <svg class="btn__icon">
                      <use xlink:href="#icon__search"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </th>
        <th class="table-3__cell  table__hcell">
          <v-select
              :options="statuses"
              :reduce="item=>item.id"
              label="title"
              v-model="filterForm.status"
              :placeholder="getTranslation('selectFromList')"
          />
        </th>
        <th class="table-3__cell  table__hcell">

        </th>
        <th class="table-3__cell  table__hcell">
          <div class="form__tillfrom-row">
            <date-picker v-model="filterForm.dateFrom" valueType="format"></date-picker>
            <div class="form__tillfrom-sep"></div>
            <date-picker v-model="filterForm.dateTo" valueType="format"></date-picker>
          </div>
        </th>
      </tr>
      <tr class="table-3__row">
        <th class="table-3__cell  table__hcell" style="width: 0.5%">#</th>
        <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('username') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('role') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('ip') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('status') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('description') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 15%">{{ getTranslation('datetime') }}</th>
      </tr>
      </thead>
      <tbody class="table-3__body">
      <tr v-for="item in items" v-bind:key="item.id" class="table-3__row">
        <td class="table-3__cell">{{ item.id }}</td>
        <td class="table-3__cell"><span v-if="item.user">{{ item.user.name }}</span></td>
        <td class="table-3__cell"><span v-if="item.user">{{ item.user.level.slug }}</span></td>
        <td class="table-3__cell">{{ item.ip }}</td>
        <td class="table-3__cell">{{ getTranslation(item.status_text) }}</td>
        <td class="table-3__cell">{{ item.info }}</td>
        <td class="table-3__cell">{{ item.created_at }}</td>
      </tr>
      </tbody>
    </table>
    <CPagination
        v-if="pagination.last_page > 1"
        class="mt-5"
        :active-page.sync="pagination.current_page"
        :pages="pagination.last_page"
    />
  </section>
</template>

<script>
export default {
  name: "Logins",
  data() {
    return {
      pagination: {
        total: 0,
        last_page: '',
        current_page: ''
      },
      filterForm: {
        page: this.$route.query.page || 1,
      },
      items: [],
      statuses: [],
      roles: [],
    }
  },
  created() {
    this.setHeadings({
      title: this.getTranslation('loginJournal'),
    });

    this.fetchItems();
    this.fetchStatuses();
    this.fetchLevels();
  },
  watch: {
    'pagination.current_page'(val) {

      if ( val )
      {
        this.filterForm.page = val;

        this.fetchItems();
      }
    },
    'filterForm.level_id'() {
      this.fetchItems();
    },
    'filterForm.status'()
    {
      this.fetchItems();
    },
    'filterForm.dateFrom'()
    {
      this.fetchItems();
    },
    'filterForm.dateTo'()
    {
      this.fetchItems();
    }
  },
  methods: {
    fetchItems() {
      this.axios.get(this.$urls.auth.logins.url, { params: this.cleanObject({ ...this.filterForm }) })
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });
    },
    fetchLevels() {
      this.axios.get(this.$urls.level.levels.url)
          .then(response => {
            this.roles = response.data.data;
          }).catch(() => {
      });
    },
    fetchStatuses() {
      this.axios.get(this.$urls.auth.statuses.url)
          .then(response => {
            this.statuses = this.objectToArray(response.data.data, true, true);
          }).catch(() => {
      });
    }
  }


}
</script>

<style scoped>

</style>